<script>
import { destroy } from '@rails/request.js'
import ProjectId from '@/mixins/project-id'
import CurrentUser from '@/mixins/current-user'
export default {
  mixins: [ProjectId, CurrentUser],
  methods: {
    async logout () {
      const response = await destroy('/api/v1/session.json')
      if (response.ok) window.location = '/login'
    }
  }
}
</script>

<template>
  <nav class="bg-gray-800">
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="flex-1 flex items-center justify-start sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <router-link to="/">
              <img class="h-4" :src="require('../assets/images/logo.svg')">
            </router-link>
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div v-if="hasProjectId" class="flex space-x-4">
              <router-link :to="{ name: 'Sources' }" class="nav-item">Sources</router-link>
              <router-link :to="{ name: 'Categories' }" class="nav-item">Categories</router-link>
              <router-link :to="{ name: 'Users' }" class="nav-item">Team</router-link>
            </div>
          </div>
        </div>
        <div class="hidden sm:block absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <template v-if="isLoggedIn">
            <span class="text-gray-100 text-sm sm:text-base">{{ currentUser.name }}</span>
            <button class="ml-1 nav-item" @click="logout">Log out</button>
          </template>
        </div>
        <template v-if="isLoggedIn">
          <div class="absolute inset-y-0 right-0 flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <button type="button" style="touchAction: 'manipulation'" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </template>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state -->
    <div id="mobile-menu" class="hidden sm:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 text-right">
        <template v-if="hasProjectId">
          <router-link :to="{ name: 'Sources' }" class="nav-item block">Sources</router-link>
          <router-link :to="{ name: 'Categories' }" class="nav-item block">Categories</router-link>
          <router-link :to="{ name: 'Users' }" class="nav-item block">Team</router-link>
        </template>
        <button v-if="isLoggedIn" class="nav-item block" @click="logout">Log out</button>
      </div>
    </div>
  </nav>
</template>
