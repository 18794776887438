<script>
import InputMixin from '@/mixins/base/input-mixin.js'
export default {
  mixins: [InputMixin],
  props: {
    options: Array
  },
  emits: ['update:modelValue'],
  methods: {
    optionId (value) {
      return [value].join('_')
    }
  }
}
</script>

<template>
  <div class="input radio_buttons">
    <label v-if="label" class="string" :for="id">{{ label }}</label>
    <span v-for="option in options" :key="option.value" class="radio">
      <input
        :id="optionId(option.value)"
        class="radio_buttons"
        type="radio"
        :name="name"
        :value="option.value"
        :checked="option.value === modelValue"
        v-bind="$attrs"
        @change="$emit('update:modelValue', option.value)"
      >
      <label class="collection_radio_buttons" :for="optionId(option.value)">
        {{ option.label }}
      </label>
    </span>
    <div v-if="hasErrors" class="error">{{ errors[name].join('; ') }}</div>
  </div>
</template>
