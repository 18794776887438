<script>
import Form from '@/mixins/form'
export default {
  mixins: [Form],
  data: function () {
    return {
      model: 'Project',
      form: { name: '' }
    }
  }
}
</script>

<template>
  <form noValidate="novalidate" acceptCharset="UTF-8" @submit="submitForm">
    <div class="form-inputs">
      <TextInput v-model="form.name" label="Name" name="name" :errors="errors" autofocus="autofocus" />
    </div>
    <div class="form-actions">
      <input type="submit" :value="submitText" class="btn w-full">
      <router-link :to="{ name: 'Projects' }" class="form-text-link">Back</router-link>
    </div>
  </form>
</template>
