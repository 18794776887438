<script>
import { get } from '@rails/request.js'

import UserRequired from '@/mixins/user-required'
import ProjectId from '@/mixins/project-id'
import CurrentUser from '@/mixins/current-user'
import User from './user'

export default {
  components: { User },
  mixins: [UserRequired, ProjectId, CurrentUser],
  data () {
    return {
      users: []
    }
  },
  mounted () {
    this.loadUsers()
  },
  methods: {
    async loadUsers () {
      const response = await get(`/api/v1/projects/${this.projectId}/users.json`)
      if (response.ok) this.users = (await response.json || [])
    },
    removeItem (id) {
      this.users = this.users.filter((user) => { return user.id !== id })
    }
  }
}
</script>

<template>
  <div class="headline-with-actions">
    <h1>Team</h1>
    <router-link :to="{ name: 'NewUser' }" class="btn">Add Member</router-link>
  </div>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th class="w-full">Name</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <User v-for="user in users" :key="user.id" :user="user" :project-id="projectId" :current-user="currentUser" @deleted="removeItem" />
      </tbody>
    </table>
  </div>
</template>
