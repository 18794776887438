<script>
import { destroy } from '@rails/request.js'
export default {
  props: ['user', 'projectId', 'currentUser'],
  emits: ['deleted'],
  methods: {
    deleteItem () {
      destroy(
        `/api/v1/projects/${this.projectId}/users/${this.user.id}.json`
      ).then((response) => {
        if (response.ok) this.$emit('deleted', this.user.id)
      })
    }
  }
}
</script>

<template>
  <tr class="category">
    <td>
      {{ user.name }}
    </td>
    <td class="action">
      <button v-if="currentUser.id != user.id" class="btn-link ml-2" @click="deleteItem">Remove</button>
    </td>
  </tr>
</template>
