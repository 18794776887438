<script>
import InputMixin from '@/mixins/base/input-mixin.js'
export default {
  mixins: [InputMixin],
  props: {
    type: { default: 'text' }
  },
  emits: ['update:modelValue']
}
</script>

<template>
  <div class="input string">
    <label v-if="label" class="string" :for="id">{{ label }}</label>
    <input
      :id="id"
      class="string"
      :type="type"
      :value="modelValue"
      v-bind="$attrs"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <div v-if="hasErrors" class="error">{{ errors[name].join('; ') }}</div>
  </div>
</template>
