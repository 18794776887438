<script>
export default {
  props: ['item', 'index'],
  emits: ['removeItem'],
  computed: {
    isHidden () {
      return (this.item._destroy === true)
    }
  }
}
</script>

<template>
  <div v-if="!isHidden" class="nested-fields flex">
    <div class="flex-grow">
      <div class="grid grid-cols-2 gap-x-3">
        <TextInput v-model="item.first_name" name="first_name" label="First Name" />
        <TextInput v-model="item.last_name" name="last_name" label="Last Name" />
      </div>
    </div>
    <div class="flex-none self-end ml-3">
      <button type="button" class="btn btn--substract" @click="$emit('removeItem', index)">-</button>
    </div>
  </div>
</template>
