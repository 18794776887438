<script>
import TheHeader from './the-header'
export default {
  components: {
    TheHeader
  }
}
</script>

<template>
  <TheHeader />
  <main>
    <div class="max-w-5xl mx-auto py-6 sm:px-6 lg:px-8 sm:mt-10">
      <slot />
    </div>
  </main>
</template>
