<script>
import Form from '@/mixins/form'
export default {
  mixins: [Form],
  data: function () {
    return {
      form: { name: '' }
    }
  }
}
</script>

<template>
  <form noValidate="novalidate" acceptCharset="UTF-8" @submit="submitForm">
    <div class="form-inputs">
      <TextInput v-model="form.email" type="email" label="Email" name="email" :errors="errors" autofocus="autofocus" />
      <TextInput v-model="form.password" type="password" label="Password" name="password" :errors="errors" />
    </div>
    <div class="form-actions">
      <input type="submit" value="Log In" class="btn w-full">
    </div>
  </form>
</template>
