<script>
import TheNav from './the-nav'
export default {
  components: {
    TheNav
  }
}
</script>

<template>
  <header>
    <TheNav />
  </header>
</template>
