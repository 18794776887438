<script>
import { get } from '@rails/request.js'
import NestedAuthors from './nested-authors'
import Kind from '@/models/kind'
import Form from '@/mixins/form'

export default {
  components: { NestedAuthors },
  mixins: [Form],
  data: function () {
    return {
      model: 'Source',
      projectId: 1,
      form: { kind: 'website', authors: [{}] },
      kinds: Kind.all,
      categories: []
    }
  },
  computed: {
    categoryOptions () {
      return this.categories.map(item => ({ label: item.name, value: item.id }))
    }
  },
  mounted: function () {
    this.loadCategories()
  },
  methods: {
    async loadCategories () {
      const response = await get(`/api/v1/projects/${this.projectId}/categories.json`)
      if (response.ok) this.categories = await response.json
    },
    isKind (kinds) {
      return !!kinds.includes(this.form.kind)
    }
  }
}
</script>

<template>
  <form noValidate="novalidate" acceptCharset="UTF-8" @submit="submitForm">
    <div class="form-inputs">
      <RadioGroupInput v-model="form.kind" name="kind" :options="kinds" :errors="errors" />
      <TextInput v-model="form.title" name="title" label="Title" :errors="errors" />
      <TextInput v-if="isKind('website')" v-model="form.url" name="url" label="Url" :errors="errors" />
      <NestedAuthors :items="form.authors" />
      <TextInput v-if="isKind('magazine_article')" v-model="form.publication" name="publication" label="Publication" :errors="errors" />
      <div class="grid grid-cols-2 gap-x-3">
        <TextInput v-if="isKind('magazine_article')" v-model="form.volume" name="volume" label="Volume" :errors="errors" />
        <TextInput v-if="isKind('magazine_article')" v-model="form.issue" name="issue" label="Issue" :errors="errors" />
      </div>
      <div class="grid grid-cols-2 gap-x-3">
        <TextInput v-if="isKind('book magazine_article')" v-model="form.pages" name="pages" label="Pages" :errors="errors" />
        <TextInput v-if="isKind('book')" v-model="form.isbn" name="isbn" label="ISBN" :errors="errors" />
        <TextInput v-if="isKind('magazine_article')" v-model="form.issn" name="issn" label="ISSN" :errors="errors" />
      </div>
      <TextAreaInput v-model="form.abstract" name="abstract" label="Abstract" :errors="errors" />
      <TextInput v-model="form.tag_names_string" name="tag_names_string" label="Tag Names" :errors="errors" />
      <SelectInput v-model="form.category_id" name="category_id" label="Category" :options="categoryOptions" :errors="errors" />
    </div>
    <div class="form-actions">
      <input type="submit" :value="submitText" class="btn w-full">
      <router-link class="form-text-link" :to="{ name: 'Sources' }">Back</router-link>
    </div>
  </form>
</template>
