<script>
import { get } from '@rails/request.js'
import Source from './source'
import FilterForm from './filter-form'
import ProjectId from '@/mixins/project-id'
import UserRequired from '@/mixins/user-required'
export default {
  components: { FilterForm, Source },
  mixins: [UserRequired, ProjectId],
  data () {
    return {
      sources: []
    }
  },
  mounted () {
    this.loadSources()
  },
  methods: {
    async loadSources (data = {}) {
      const response = await get(`/api/v1/projects/${this.projectId}/sources.json`, { query: data })
      if (response.ok) this.sources = (await response.json || [])
    },
    filter (data) {
      this.loadSources(data)
    },
    removeItem (id) {
      this.sources = this.sources.filter((source) => { return source.id !== id })
    }
  }
}
</script>

<template>
  <div class="headline-with-actions">
    <h1>Sources</h1>
    <router-link :to="{ name: 'NewSource' }" class="btn">Add Source</router-link>
  </div>
  <div class="filter-section">
    <FilterForm :project-id="projectId" @filter="filter" />
  </div>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th class="w-full">Title</th>
          <th class="hidden md:table-cell">Type</th>
          <th class="hidden md:table-cell">Category</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <Source v-for="source in sources" :key="source.id" :source="source" :project-id="projectId" @deleted="removeItem" />
      </tbody>
    </table>
  </div>
</template>
