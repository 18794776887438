<script>
import InputMixin from '@/mixins/base/input-mixin.js'
export default {
  mixins: [InputMixin],
  props: {
    options: Array,
    placeholder: String
  },
  emits: ['update:modelValue']
}
</script>

<template>
  <div class="input select">
    <label v-if="label" class="select" :for="id">{{ label }}</label>
    <select
      :id="id"
      :name="name"
      :value="modelValue"
      v-bind="$attrs"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option value="">{{ placeholder }}</option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="option.value === modelValue"
      >
        {{ option.label }}
      </option>
    </select>
    <div v-if="hasErrors" class="error">{{ errors[name].join('; ') }}</div>
  </div>
</template>
