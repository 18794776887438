<script>
import Form from './form'
import ProjectId from '@/mixins/project-id'
import UserRequired from '@/mixins/user-required'
export default {
  components: { Form },
  mixins: [UserRequired, ProjectId],
  methods: {
    afterSave () {
      this.$router.push({ name: 'Users', params: { projectId: this.projectId } })
    }
  }
}
</script>

<template>
  <div class="max-w-md mx-auto">
    <div class="headline-with-actions">
      <h1>New Member</h1>
    </div>
    <div class="form-card-wrapper">
      <div class="form-card">
        <Form method="post" :url="`/api/v1/projects/${projectId}/users.json`" @afterSave="afterSave" />
      </div>
    </div>
  </div>
</template>
