<script>
import { get } from '@rails/request.js'
import Category from './category'
import ProjectId from '@/mixins/project-id'
import UserRequired from '@/mixins/user-required'
export default {
  components: { Category },
  mixins: [UserRequired, ProjectId],
  data () {
    return {
      categories: []
    }
  },
  mounted () {
    this.loadCategories()
  },
  methods: {
    async loadCategories () {
      const response = await get(`/api/v1/projects/${this.projectId}/categories.json`)
      if (response.ok) this.categories = (await response.json || [])
    },
    removeItem (id) {
      this.categories = this.categories.filter((category) => { return category.id !== id })
    }
  }
}
</script>

<template>
  <div class="headline-with-actions">
    <h1>Categories</h1>
    <router-link :to="{ name: 'NewCategory' }" class="btn">Add Category</router-link>
  </div>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th class="w-full">Name</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <Category v-for="category in categories" :key="category.id" :category="category" :project-id="projectId" @deleted="removeItem" />
      </tbody>
    </table>
  </div>
</template>
