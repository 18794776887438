<script>
import Form from './form'
export default {
  components: { Form },
  inject: ['state'],
  methods: {
    afterSave (data) {
      this.state.currentUser = data
      this.$router.push('/')
    }
  }
}
</script>

<template>
  <div class="max-w-md mx-auto">
    <div class="headline-with-actions">
      <h1>Log In</h1>
    </div>
    <div class="form-card-wrapper">
      <div class="form-card">
        <Form method="post" url="/api/v1/sessions.json" @afterSave="afterSave" />
      </div>
    </div>
  </div>
</template>
