<script>
import TheLayout from './components/the-layout'
import TheAuthentication from './components/the-authentication'
export default {
  components: {
    TheAuthentication,
    TheLayout
  }
}
</script>

<template>
  <TheAuthentication>
    <TheLayout>
      <router-view />
    </TheLayout>
  </TheAuthentication>
</template>
