<script>
import { get } from '@rails/request.js'
import Project from './project'
import UserRequired from '@/mixins/user-required'
export default {
  components: { Project },
  mixins: [UserRequired],
  data () {
    return {
      projects: []
    }
  },
  mounted () {
    this.loadProjects()
  },
  methods: {
    async loadProjects () {
      const response = await get('/api/v1/projects.json')
      if (response.ok) this.projects = (await response.json || [])
    },
    removeItem (id) {
      this.projects = this.projects.filter((project) => { return project.id !== id })
    }
  }
}
</script>

<template>
  <div class="headline-with-actions">
    <h1>Projects</h1>
    <router-link :to="{ name: 'NewProject' }" class="btn">Add Project</router-link>
  </div>
  <div class="card-grid">
    <Project v-for="project in projects" :key="project.id" :project="project" @deleted="removeItem" />
  </div>
</template>
