import { createRouter, createWebHistory } from 'vue-router'

import NewSession from '@/components/sessions/new-session'

import Projects from '@/components/projects/projects'
import NewProject from '@/components/projects/new-project'
import EditProject from '@/components/projects/edit-project'

import Sources from '@/components/sources/sources'
import NewSource from '@/components/sources/new-source'
import EditSource from '@/components/sources/edit-source'

import Categories from '@/components/categories/categories'
import NewCategory from '@/components/categories/new-category'
import EditCategory from '@/components/categories/edit-category'

import Users from '@/components/users/users'
import NewUser from '@/components/users/new-user'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: NewSession
  },
  {
    path: '/',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/projects/new',
    name: 'NewProject',
    component: NewProject
  },
  {
    path: '/projects/:id/edit',
    name: 'EditProject',
    component: EditProject
  },
  {
    path: '/projects/:projectId/sources',
    name: 'Sources',
    component: Sources
  },
  {
    path: '/projects/:projectId/sources/new',
    name: 'NewSource',
    component: NewSource
  },
  {
    path: '/projects/:projectId/sources/:id/edit',
    name: 'EditSource',
    component: EditSource
  },
  {
    path: '/projects/:projectId/categories',
    name: 'Categories',
    component: Categories
  },
  {
    path: '/projects/:projectId/categories/new',
    name: 'NewCategory',
    component: NewCategory
  },
  {
    path: '/projects/:projectId/categories/:id/edit',
    name: 'EditCategory',
    component: EditCategory
  },
  {
    path: '/projects/:projectId/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/projects/:projectId/users/new',
    name: 'NewUser',
    component: NewUser
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
