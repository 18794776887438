<script>
import { destroy } from '@rails/request.js'
export default {
  props: ['project'],
  emits: ['deleted'],
  methods: {
    deleteItem () {
      destroy(
        `/api/v1/projects/${this.project.id}.json`
      ).then((response) => {
        if (response.ok) this.$emit('deleted', this.project.id)
      })
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="title">
      <router-link :to="{ name: 'Sources', params: { projectId: project.id} }" class="title--link">{{ project.name }}</router-link>
    </div>
    <div class="actions">
      <router-link :to="{ name: 'EditProject', params: { id: project.id} }" class="mr-2">Edit</router-link>
      <button class="btn-link ml-2" @click="deleteItem">Destroy</button>
    </div>
  </div>
</template>
