<script>
import { destroy } from '@rails/request.js'
export default {
  props: ['category', 'projectId'],
  emits: ['deleted'],
  methods: {
    deleteItem () {
      destroy(
        `/api/v1/projects/${this.projectId}/categories/${this.category.id}.json`
      ).then((response) => {
        if (response.ok) this.$emit('deleted', this.category.id)
      })
    }
  }
}
</script>

<template>
  <tr class="category">
    <td>
      {{ category.name }}
    </td>
    <td class="action">
      <router-link
        :to="{ name: 'EditCategory', params: { id: category.id } }"
        class="mr-2"
      >
        Edit
      </router-link>
      <button class="btn-link ml-2" @click="deleteItem">Destroy</button>
    </td>
  </tr>
</template>
