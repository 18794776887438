const InputMixin = {
  props: {
    name: String,
    label: String,
    modelValue: { default: '' },
    errors: { default: {} }
  },
  computed: {
    id () {
      return ['field', this.name].join('_')
    },
    hasErrors () {
      return !!this.errors[this.name]
    }
  }
}

export default InputMixin
