<script>
import AuthorFields from './author-fields'
export default {
  components: { AuthorFields },
  props: ['items'],
  methods: {
    addItem () {
      this.items.push({})
    },
    removeItem (index) {
      const item = this.items[index]
      if (item.id) {
        item._destroy = true
      } else {
        this.items.splice(index, 1)
      }
    }
  }
}
</script>

<template>
  <div class="nested-form">
    <AuthorFields
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :index="index"
      @removeItem="removeItem"
    />
    <div class="action">
      <button type="button" class="btn-link" @click="addItem">Add Author</button>
    </div>
  </div>
</template>
