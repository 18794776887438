class Kind {
  static get all () {
    const values = [
      { label: 'Book', value: 'book' },
      { label: 'Website', value: 'website' },
      { label: 'Magazine Article', value: 'magazine_article' }
    ]

    return values
  }
}

export default Kind
