const CurrentUser = {
  inject: ['state'],
  computed: {
    isLoggedIn () {
      return !!this.state.currentUser
    },
    currentUser () {
      return this.state.currentUser
    }
  }
}

export default CurrentUser
