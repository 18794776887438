<script>
import { destroy } from '@rails/request.js'
export default {
  props: ['source', 'projectId'],
  emits: ['deleted'],
  methods: {
    deleteItem () {
      destroy(
        `/api/v1/projects/${this.projectId}/sources/${this.source.id}.json`
      ).then((response) => {
        if (response.ok) this.$emit('deleted', this.source.id)
      })
    }
  }
}
</script>

<template>
  <tr class="source">
    <td>
      {{ source.title }}
      <a target="_blank" :href="source.url">&nbsp;+</a>
    </td>
    <td class="hidden md:table-cell">{{ source.kind }}</td>
    <td class="hidden md:table-cell">{{ source.category && source.category.name }}</td>
    <td class="action">
      <router-link
        :to="{ name: 'EditSource', params: { id: source.id } }"
        class="mr-2"
      >
        Edit
      </router-link>
      <button class="btn-link ml-2" @click="deleteItem">Destroy</button>
    </td>
  </tr>
</template>
