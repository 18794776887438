<script>
import { get } from '@rails/request.js'
export default {
  inject: ['state'],
  data () {
    return {
      isLoaded: false
    }
  },
  created () {
    this.loadSession()
  },
  methods: {
    async loadSession () {
      const response = await get('/api/v1/session.json')
      if (response.ok) {
        this.state.currentUser = await response.json
      }
      this.isLoaded = true
    }
  }
}
</script>

<template>
  <template v-if="isLoaded">
    <slot />
  </template>
</template>
