import { FetchRequest, get } from '@rails/request.js'

const Form = {
  props: ['method', 'url'],
  data: function () {
    return {
      model: null,
      form: {},
      errors: {}
    }
  },
  methods: {
    async submitForm (event) {
      event.preventDefault()
      const request = new FetchRequest(this.method, this.url, { body: JSON.stringify(this.form) })
      const response = await request.perform()
      if (response.ok) {
        this.$emit('afterSave', await response.json)
      } else {
        this.errors = await response.json
      }
    },
    async loadRecord () {
      const response = await get(this.url)
      if (response.ok) this.form = await response.json
    }
  },
  computed: {
    isEditing () {
      return this.method === 'put'
    },
    submitText () {
      return [this.isEditing ? 'Update' : 'Create', this.model].join(' ')
    }
  },
  mounted: function () {
    if (this.isEditing) this.loadRecord()
  }
}

export default Form
