<script>
import { get } from '@rails/request.js'
import Kind from '@/models/kind'
import Form from '@/mixins/form'

export default {
  mixins: [Form],
  props: ['projectId'],
  emits: ['filter'],
  data () {
    return {
      kinds: Kind.all,
      categories: [],
      tags: []
    }
  },
  computed: {
    categoryOptions () {
      return this.categories.map(item => ({ label: item.name, value: item.id }))
    },
    tagOptions () {
      return this.tags.map(item => ({ label: item.name, value: item.name }))
    },
    inputChanged () {
      return [this.form.category, this.form.kind, this.form.tag, this.form.q]
    }
  },
  watch: {
    inputChanged: function () { this.filter() }
  },
  mounted () {
    this.loadCategories(),
    this.loadTags()
  },
  methods: {
    async loadCategories () {
      const response = await get(`/api/v1/projects/${this.projectId}/categories.json`)
      if (response.ok) this.categories = await response.json
    },
    async loadTags () {
      const response = await get(`/api/v1/projects/${this.projectId}/tags.json`)
      if (response.ok) this.tags = await response.json
    },
    filter () {
      this.$emit('filter', this.form)
    }
  }
}
</script>

<template>
  <form noValidate="novalidate" acceptCharset="UTF-8" @submit.prevent="filter">
    <div class="grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-4">
      <SelectInput v-model="form.category" name="category" :options="categoryOptions" placeholder="All Categories" />
      <SelectInput v-model="form.kind" name="kind" :options="kinds" placeholder="All Types" />
      <SelectInput v-model="form.tag" name="tag" :options="tagOptions" placeholder="All Tags" />
      <TextInput v-model="form.q" name="q" placeholder="Title or abstract" />
    </div>
  </form>
</template>
