<script>
import Form from './form'
import UserRequired from '@/mixins/user-required'
export default {
  components: { Form },
  mixins: [UserRequired],
  methods: {
    afterSave () {
      this.$router.push({ name: 'Projects' })
    }
  }
}
</script>

<template>
  <div class="max-w-md mx-auto">
    <div class="headline-with-actions">
      <h1>New Project</h1>
    </div>
    <div class="form-card-wrapper">
      <div class="form-card">
        <Form method="post" url="/api/v1/projects.json" @afterSave="afterSave" />
      </div>
    </div>
  </div>
</template>
