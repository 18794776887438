// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { createApp } from 'vue'
import App from '@/app.vue'
import router from '@/router'
import store from '@/store'

import TextInput from '@/components/base/text-input.vue'
import TextAreaInput from '@/components/base/text-area-input.vue'
import RadioGroupInput from '@/components/base/radio-group-input.vue'
import SelectInput from '@/components/base/select-input.vue'

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp(App)

  app.use(router)
  app.provide('state', store.state)

  app.component('TextInput', TextInput)
  app.component('TextAreaInput', TextAreaInput)
  app.component('RadioGroupInput', RadioGroupInput)
  app.component('SelectInput', SelectInput)

  const vm = app.mount('#app')
  console.log(vm)
})
