const ProjectId = {
  computed: {
    projectId () {
      return this.$route.params.projectId
    },
    hasProjectId () {
      return !!this.projectId
    }
  }
}

export default ProjectId
