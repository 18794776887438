<script>
import InputMixin from '@/mixins/base/input-mixin.js'
export default {
  mixins: [InputMixin],
  emits: ['update:modelValue']
}
</script>

<template>
  <div class="input text">
    <label v-if="label" class="text" :for="id">{{ label }}</label>
    <textarea
      :id="id"
      class="text"
      :value="modelValue"
      v-bind="$attrs"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div v-if="hasErrors" class="error">{{ errors[name].join('; ') }}</div>
  </div>
</template>
